/*
 * Module img-and-text-section (img-and-text-section)
 * Added by karolinanorstrom, 2024-02-12T20:07:41.243Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file img-and-text-section.scss is also used on mobile devices.
 */
section.img-and-text-section {
  display: block !important;
  margin-left: 20px;
  margin-right: 20px;
}
section.img-and-text-section .image-container {
  width: 100%;
}
section.img-and-text-section .heading-container {
  margin-left: 0px;
  width: 100%;
  margin-top: 36px;
}