/*
 * Module how-does-it-work-block (how-does-it-work-block)
 * Added by karolinanorstrom, 2024-02-16T09:14:37.118Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file how-does-it-work-block.scss is also used on mobile devices.
 */
section.how-does-it-work-block {
  margin-left: 20px;
  margin-right: 20px;
}
section.how-does-it-work-block .outer-container h1 {
  font-size: var(--h3-size);
}
section.how-does-it-work-block .outer-container .vline {
  left: 15px;
}
section.how-does-it-work-block .outer-container .left-block .last-line {
  left: 15px;
}
section.how-does-it-work-block .outer-container .inner-container {
  margin-left: 70px;
  width: calc(100% - 70px);
}
section.how-does-it-work-block .outer-container .inner-container .background-text {
  left: -30px;
}