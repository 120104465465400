/*
 * Module two-column-images (two-column-images)
 * Added by karolinanorstrom, 2024-02-15T11:43:35.845Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file two-column-images.scss is also used on mobile devices.
 */
section.two-column-images {
  margin-left: 20px;
  margin-right: 20px;
}
section.two-column-images .image-container {
  display: block;
  height: auto;
}
section.two-column-images .image-container .image-container-inner {
  width: 100%;
}