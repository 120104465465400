/*
 * Module image-and-text-full-width-block (image-and-text-full-width-block)
 * Added by karolinanorstrom, 2024-02-14T10:47:23.148Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file image-and-text-full-width-block.scss is also used on mobile devices.
 */
section.image-and-text-full-width-block {
  margin-left: 20px;
  margin-right: 20px;
}
section.image-and-text-full-width-block .text-container {
  display: block;
}
section.image-and-text-full-width-block .text-container p {
  width: 100%;
}