/*
 * Module three-column-text-and-two-images (three-column-text-and-two-images)
 * Added by karolinanorstrom, 2024-02-13T13:22:02.916Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file three-column-text-and-two-images.scss is also used on mobile devices.
 */
section.three-column-text-and-two-images {
  display: block !important;
  margin-left: 20px;
  margin-right: 20px;
}
section.three-column-text-and-two-images .content-container {
  display: block;
}
section.three-column-text-and-two-images .heading-container {
  width: 100%;
}
section.three-column-text-and-two-images .image-container {
  display: block;
  height: auto;
}
section.three-column-text-and-two-images .image-container .image-container-inner {
  width: 100%;
  height: auto;
  margin-top: 30px;
}