/*
 * Module image-full-width-block (image-full-width-block)
 * Added by karolinanorstrom, 2024-02-13T14:46:33.392Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file image-full-width-block.scss is also used on mobile devices.
 */
section.image-full-width-block {
  margin-left: 20px;
  margin-right: 20px;
}
section.image-full-width-block .image {
  height: 230px;
}