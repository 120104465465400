/*
 * Module reference-logotype-section (reference-logotype-section)
 * Added by karolinanorstrom, 2024-04-16T13:09:32.790Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file reference-logotype-section.scss is also used on mobile devices.
 */
section.reference-logotype-section {
  padding: 0 20px;
}
section.reference-logotype-section .heading-container {
  margin-bottom: 20px;
}
section.reference-logotype-section .reference-logo-container {
  gap: 20px;
}
section.reference-logotype-section .reference-logo-container a {
  display: flex;
  max-width: calc(30% - 10px);
  justify-content: center;
}
section.reference-logotype-section .reference-logo-container a img {
  width: 100%;
  display: block;
}