/*
 * Module text-section (text-section)
 * Added by karolinanorstrom, 2024-02-01T14:00:39.551Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file text-section.scss is also used on mobile devices.
 */
section.text-section {
  padding: 0 20px;
}
section.text-section .text-container h2 {
  font-size: var(--h3-size);
}