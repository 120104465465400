/*
 * Module text-section-and-img (text-section-and-img)
 * Added by karolinanorstrom, 2024-02-21T12:32:47.669Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file text-section-and-img.scss is also used on mobile devices.
 */
section.text-section-and-img {
  margin-left: 20px;
  margin-right: 20px;
  display: block !important;
}
section.text-section-and-img .heading-container {
  width: 100%;
  margin-left: 0px;
  margin-bottom: 24px;
}
section.text-section-and-img .image-container {
  width: 100%;
}