/*
 * Module text-area-full-width (text-area-full-width)
 * Added by karolinanorstrom, 2024-03-04T14:41:27.766Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file text-area-full-width.scss is also used on mobile devices.
 */
section.text-area-full-width .area-container {
  margin-right: 20px;
  margin-left: 20px;
}