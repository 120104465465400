/*
 * Module suppliers-section (suppliers-section)
 * Added by viktorandersson, 2024-02-22T12:53:43.154Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file suppliers-section.scss is also used on mobile devices.
 */
section.suppliers-section {
  padding: 0 20px;
}
section.suppliers-section .suppliers-container {
  gap: 20px;
}
section.suppliers-section .suppliers-container a {
  display: block;
  max-width: calc(50% - 10px);
}
section.suppliers-section .suppliers-container a img {
  width: 100%;
  display: block;
}