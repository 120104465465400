/*
 * Module two-column-text-and-image-right (two-column-text-and-image-right)
 * Added by karolinanorstrom, 2024-02-13T12:48:50.625Z
 *
 * Do not panic, this file is automatically wrapped in a mobile media query.
 * Nota bene the file two-column-text-and-image-right.scss is also used on mobile devices.
 */
section.two-column-text-and-image-right {
  margin-left: 20px;
  margin-right: 20px;
  display: block !important;
  text-align: center;
}
section.two-column-text-and-image-right .heading-container {
  width: 100%;
  display: block;
  padding-bottom: 24px;
}
section.two-column-text-and-image-right .heading-container p {
  margin: 0;
}
section.two-column-text-and-image-right .heading-container h2 {
  color: var(--mobile-h1-color);
  font-size: var(--mobile-h1-size);
}
section.two-column-text-and-image-right .image-container {
  width: 100%;
  display: block;
}